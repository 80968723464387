import filter from 'lodash/filter';
import head from 'lodash/head';
import replace from 'lodash/replace';

export default searchInput => contact => {
  const fullName = contact.fullName ? contact.fullName.toLowerCase().split(' ') : '';
  const searchInputDigits = replace(searchInput, /[^0-9]/g, '');
  const phoneNumberDigits = contact.phoneNumber ? replace(contact.phoneNumber, /[^0-9]/g, '') : '';
  return searchInputDigits !== ''
    ? phoneNumberDigits.includes(searchInputDigits) && phoneNumberDigits.length >= searchInputDigits.length
    : filter(fullName, name => name.includes(searchInput.toLowerCase())).length > 0;
};
