import React from "react";
import withTranslation from "../hocs/withTranslation";
import { connect } from "react-redux";
import ContactsView from "../components/ContactsView";
import { getStyles } from "../store/selectors";
import {
  loadContacts,
  selectContactToShareGift,
  selectContactForGiftCard,
} from "../store/user/actions";

const ContactPage = (props) => <ContactsView {...props} />;

const mapStateToProps = (state, props) => {
  const {
    user,
    app: { keyboardOpen },
  } = state;

  return { user, keyboardOpen, appStyles: getStyles(state, props) };
};

const mapDisaptchToProps = (dispatch, props) => {
  return {
    loadContacts: () => dispatch(loadContacts()),
    selectContactToShareGift: (contactDetails) =>
      dispatch(selectContactToShareGift(contactDetails)),
    selectContactForGiftCard: (contactDetails) =>
      dispatch(selectContactForGiftCard(contactDetails)),
  };
};

export default connect(
  mapStateToProps,
  mapDisaptchToProps,
)(withTranslation(ContactPage));
