import classnames from "classnames";
import _ from "lodash";
import React from "react";
import { getParams, setParams } from "../../utils/location";
import AppContainer from "../AppContainer";
import Button from "../Button";
import ChevronRightIcon from "../icons/ChevronRight.svg";
import { Input } from "../Inputs";
import List from "../List";
import Loader from "../Loader";
import UserAvatar from "../UserAvatar";
import contactsFilter from "./contactsFilter";
import * as styles from "./index.module.scss";

export default class extends React.Component {
  state = {
    searchInput: "",
    index: null,
  };

  componentDidMount() {
    window.isNativeApp && this.props.loadContacts();
  }

  updateFilter = (searchInput) => {
    this.setState({ searchInput: searchInput });
  };

  isPhoneNumberValid = (number) => {
    const cleanedPhoneInput = this.cleanPhoneNumber(number);

    return (cleanedPhoneInput.match(/^(\+\d{1,3}[- ]?)?\d{10}$/) ||
      ("+" + cleanedPhoneInput).match(/^(\+\d{1,3}[- ]?)?\d{10}$/)) &&
    !cleanedPhoneInput.match(/0{5,}/);
  };

  cleanPhoneNumber = (number) => {
    const retVal = _.replace(_.replace(_.replace(_.replace(number, /-/g, ""), /\(/g, ""), /\)/g, ""), / /g, "");
    return retVal;
  }

  getFilteredContacts = () => {
    const { searchInput } = this.state;
    const {
      user: { contacts },
    } = this.props;

    if (contacts && searchInput !== "") {
      const filteredContacts = _.filter(contacts, contactsFilter(searchInput));
      if (_.isEmpty(filteredContacts)) {
        return this.isPhoneNumberValid(searchInput)
          ? [{ phoneNumber: this.cleanPhoneNumber(searchInput) }]
          : [];
      }
      return filteredContacts;
    }

    return contacts;
  };

  render() {
    const {
      T,
      keyboardOpen,
      user: { loadingContacts, loadingContactsError, contacts },
      location,
      pageContext: {
        charities,
        business: { name },
      },
      appStyles,
    } = this.props;

    const fromGiftCard = getParams(location).fromGiftCard;

    const { PageHeader = {} } = appStyles;

    if (loadingContacts) {
      return (
        <AppContainer.Content
          tightBottom
          style={{ minHeight: "calc(100vh - 60px)" }}
          appStyles={appStyles}
        >
          <AppContainer.CenteredColumn style={{ marginTop: "45%" }}>
            <Loader appStyles={appStyles} />
            <strong>{T("Loading your contacts")}</strong>
          </AppContainer.CenteredColumn>
        </AppContainer.Content>
      );
    }

    return (
      <AppContainer.Content
        tightBottom
        style={{ paddingTop: keyboardOpen ? 86 : 146 }}
        appStyles={appStyles}
      >
        <AppContainer.CenteredColumn
          style={{
            position: "fixed",
            top: 60,
            left: 0,
            zIndex: 1,
            backgroundColor: appStyles.backgroundColor,
            width: "calc(100% - 32px)",
            paddingTop: 6,
            paddingLeft: 16,
            paddingRight: 16,
            boxShadow: appStyles.gradientStyleTypeDark
              ? "0 0 16px 0px #111"
              : "0 0 16px 0px #888",
          }}
        >
          <div
            className={classnames(
              styles.ContactsHeader,
              appStyles.rtl && styles.RTL,
            )}
          >
            <span
              style={{
                ...PageHeader,
                color: appStyles.accentColor,
                fontSize: 22,
              }}
            >
              {fromGiftCard
                ? T("Who would you like to send to?")
                : T("Who do you want to share with?")}
            </span>
            <Input
              style={{ marginTop: 4 }}
              placeholder={T("Enter Name or Phone Number")}
              onChange={this.updateFilter}
              appStyles={appStyles}
              noCheckmark
            />
          </div>
        </AppContainer.CenteredColumn>
        {loadingContactsError && this.state.searchInput === "" ? (
          <AppContainer.CenteredColumn>
            <h4 style={PageHeader}>{T("Could not load your contacts :(")}</h4>
            <p style={{ color: appStyles.accentColor }}>
              {fromGiftCard
                ? T(
                    "Allow access to your contacts in order to send a gift card.",
                  )
                : T(
                    "Allow access to your contacts in order to share a reward.",
                  )}
            </p>
            <Button appStyles={appStyles} onClick={this.props.loadContacts}>
              {T("Try Again")}
            </Button>
          </AppContainer.CenteredColumn>
        ) : (
          <List>
            {_.map(this.getFilteredContacts(), (contact, index) => (
              <List.Item
                style={{ padding: 0 }}
                key={index}
                to={
                  fromGiftCard
                    ? setParams("/gift-card", getParams(location))
                    : setParams("/share", getParams(location))
                }
                onClick={() =>
                  fromGiftCard
                    ? this.props.selectContactForGiftCard(contact)
                    : this.props.selectContactToShareGift(contact)
                }
              >
                <List.Item.Content>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <UserAvatar
                        backgroundColor={appStyles.accentColor}
                        avatarUrl={
                          contact.imageDataAvailable &&
                          contact.thumbnailImageData
                        }
                        name={contact.fullName}
                        size={60}
                        baseFontSize={appStyles.baseFontSize}
                      />
                    </div>
                    <div>
                      <p style={{ fontWeight: "bold" }}>
                        {contact.fullName
                          ? contact.fullName
                          : T("Unkown Number")}
                      </p>
                      <p>{contact.phoneNumber || ""}</p>
                    </div>
                  </div>
                </List.Item.Content>
                <ChevronRightIcon
                  className={classnames(
                    styles.ChevronRightIcon,
                    appStyles.rtl && styles.RTL,
                  )}
                />
              </List.Item>
            ))}
          </List>
        )}
      </AppContainer.Content>
    );
  }
}
